import React, { useState } from 'react';
import './App.css';
import axios from 'axios';
import config from './config.dev';

const apiEndpoint = config.apiBaseUrl + '/family/emailGiftList';
function Reports() {
    const [isDisabled, setIsDisabled] = useState(false);
    const [password, setPassword] = useState('');
    const [admin, setAdmin] = useState('');

    const [isVerified, setIsVerified] = useState(false);
    const [date, setDate] = useState('');

    const correctPassword = 'Admin2024';


    const handlePrint = async (e) => {
        e.preventDefault();
         if (!isValidAdmin(admin)) {
                          alert("Invalid Administrator, please use the dropdown menu to select one");
                          return;
                      }
        setIsDisabled(true);

        try {
            console.log("the admin value being transferred is ", admin);
            const response = await axios.post(`/api/family/emailGiftList?admin=${admin}`);
                  if (response.status < 300) {
                alert(response.data);
            } else {
                alert("An unexpected response was received.");
            }
        } catch (error) {
            if (error.response) {
                alert(error.response.data);
            } else {
                alert("Failed to connect to the server.");
            }
        } finally {
            setIsDisabled(false);
        }
    };

   const handleFilteredPrint = async (e) => {
       e.preventDefault();
       if (!isValidDate(date)) {
           alert("Invalid date. Please enter a valid date in YYYY-MM-DD format.");
           return;
       }
       setIsDisabled(true);
        if (!isValidAdmin(admin)) {
                  alert("Invalid Administrator, please use the dropdown menu to select one");
                  return;
              }
        setIsDisabled(true);
       try {
           console.log("Date being sent:", date);
const response = await axios.post(
    `/api/family/filteredEmailGiftList?tagDate=${date}&admin=${admin}`, {
        _t: new Date().getTime()
    }
);
           if (response.status < 300) {
               alert(response.data);
           } else {
               alert("An unexpected response was received.");
           }
       } catch (error) {
           if (error.response) {
               alert(error.response.data);
           } else {
               alert("Failed to connect to the server.");
           }
       } finally {
           setIsDisabled(false);
       }
   };

   const handleVerifyPassword = () => {
       if (password === correctPassword) {
           setIsVerified(true);
           alert("Password verified successfully!");
       } else {
           alert("Incorrect password. Please try again.");
       }
   };

     function isValidAdmin(admin) {

            if (!admin>0 && admin<3) {
                return false;
            }
            return true;
        }

    function isValidDate(dateString) {
        const dateRegex = /^\d{4}-\d{2}-\d{2}$/;
        if (!dateRegex.test(dateString)) {
            return false;
        }
        const [year, month, day] = dateString.split('-').map(Number);
        if (year > 2030 || month < 1 || month > 12 || day < 1 || day > 31) {
            return false;
        }
        const daysInMonth = new Date(year, month, 0).getDate();
        return day <= daysInMonth;
    }

    return (
        <div>
            <h1>Reports Page</h1>

            {!isVerified && (
              <div style={{ display: 'flex', alignItems: 'center', gap: '10px' }}>
                  <input
                      type="password"
                      placeholder="Enter Password"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                      className="large-input"
                  />
                  <button onClick={handleVerifyPassword} className="large-button">
                      Verify Password
                  </button>
              </div>
            )}

            <div>
                {/* Dropdown Menu for Admin Selection */}
                <label htmlFor="admin-select">Select User:</label>
                <select
                    id="admin-select"
                    onChange={(e) => setAdmin(Number(e.target.value))}
                    className="large-input"
                >
                    <option value="" disabled selected>
                        Choose an Admin
                    </option>
                    <option value="1">Dennis</option>
                    <option value="2">Steve</option>
                </select>
            </div>


            <div className="date-input-container">


        <button
            onClick={handlePrint}
            disabled={!isVerified || isDisabled}
            className="large-button"
            style={{ marginLeft: 0 }}
        >
            Print All Families
        </button>

               <button onClick={handleFilteredPrint} disabled={!isVerified || isDisabled} className="large-button">
                                   Print Families Entered After a Specified Date
                               </button>
               <input
                   type="text"
                   placeholder="Enter Date (YYYY-MM-DD)"
                   value={date}
                   onChange={(e) => setDate(e.target.value)}
                   className="large-input"
                   style={{ width: '900px' }} // Adjust the width as needed

               />

            </div>
        </div>
    );
}

export default Reports;